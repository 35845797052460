import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({
    typography,
}) => ({
    title: {
        ...typography.title,
        lineHeight: "44px",
    },
    subtitle: {
        margin: "4px 0 18px 0",
    },
}),
);