import React from "react";

import { useAuth } from "@api/auth";

import { isProductEnabled } from "@utils/products";

import { createDriveView, ViewComponent } from "../_lib/view";
import { DriveProvider } from "@drive/context";
import { Display } from "./_lib/Display";

import { ProductType } from "@api/products";
import { Redirect } from "~/components/Redirect";

const UpcomingView: ViewComponent = () => {
    const { selectedCompany } = useAuth();

    const isDriveToolEnabled = isProductEnabled(selectedCompany, ProductType.DriveTool);
    if (!isDriveToolEnabled) {
        return <Redirect to="/drive/" />;
    }

    return (
        <DriveProvider>
            <Display />
        </DriveProvider>
    );
};

export default createDriveView(UpcomingView, {
    title: "Upcoming",
    internalPad: true,
    internalScroll: true,
});