import React, { useMemo } from "react";

import { useDriveContext } from "@drive/context";

import Box from "@material-ui/core/Box";

import { Spinner } from "~/components/Spinner";
import Typography from "@material-ui/core/Typography";
import { NoBookings } from "../../_lib/NoBookings";

export const Display: React.FC = () => {
    const { bookings, loading, error } = useDriveContext();

    const content = useMemo(() => {
        if (loading) {
            return <Spinner />;
        }

        if (!bookings.length) {
            return <div style={{ padding: 20 }}>
                <NoBookings />
            </div>;
        }

        return (<Typography variant="caption">Newest List</Typography>);
    }, [bookings, loading]);


    return (
        <Box
            display="flex"
            justifyContent={loading || error ? "center" : ""}
            flexDirection="column"
            flexWrap="nowrap"
            height="100%"
        >
            {content}
        </Box>
    );
};