import Box from "@material-ui/core/Box";
import { InfoCard } from "~/components/InfoCard";
import { PrimaryButton } from "~/components/Button";
import React from "react";
import { Typography } from "~/components/Typography";
import { useStyles } from "./styles";
import { navigate } from "@utils/navigate";
import { useAuth } from "@api/auth";

export const NoBookings: React.FC = () => {
    const styles = useStyles();
    const { user } = useAuth();
    const widgetId = user?.selectedCompany?.widgetId;

    const onClick = () => {
        navigate(`/ava-docs/?widgetId=${widgetId}`);
    };
    return (
        <InfoCard title={"Test Drive"}>
            <Box
                display="flex"
                flexDirection="column"
                flexWrap="nowrap"
                justifyContent="center"
                alignItems="center"
                margin="100px"
                height="100%"
            >
                <Typography className={styles.title}>Setup AVA Drive now</Typography>
                <Typography className={styles.subtitle}>Get started with AVA Drive and install it on your website</Typography>
                <PrimaryButton
                    onClick={onClick}
                >
                    <Typography variant="inherit">Install AVA Drive</Typography>
                </PrimaryButton>
            </Box>
        </InfoCard>
    );
};

